<template>
  <div>
    <div v-if="Object.keys(hub_stock).length !== 0">
      <b-modal id="hub-product-stock-consumption" :title="'Update - '+ hub_stock.name" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label for="total_quantity" class="col-form-label col-form-label-sm">Available Stock</label>
              <input type="text" disabled readonly v-model="hub_stock.total_quantity" id="total_quantity" name="total_quantity" class="form-control form-control-sm"/>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label for="total_quantity_remaining" class="col-form-label col-form-label-sm">Remaining Stock</label>
              <input type="text" disabled readonly v-model="hub_stock.total_quantity_remaining" id="total_quantity_remaining" name="total_quantity_remaining" class="form-control form-control-sm"/>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label for="consumption_amount" class="col-form-label col-form-label-sm">Consumption Qty <span class="custom-required">*</span></label>
              <input :disabled="hub_stock.total_quantity_remaining == 0 ? true : false" type="number" v-model="hub_stock.consumption_amount" v-validate="'required|numeric'" @change="newInboundQuantity($event.target.value, hub_stock.total_quantity_remaining)"  id="consumption_amount" name="consumption_amount" class="form-control form-control-sm" placeholder="Enter product stock consumption"/>
              <div class="invalid-feedback">Product stock consumption is required</div>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Consumption Qty</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('hub-product-stock-consumption')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'hubStockUpdate',
  props: ['hub_stock', 'show'],
  data() {
    return {
      validation_errors: {},
      loader: false,
    }
  },
  mounted() {},
  methods: {
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/hub/free/product-stock/consumption/update', this.hub_stock)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                this.$bvModal.hide('hub-product-stock-consumption')
                this.$emit('search')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$bvModal.hide('hub-product-stock-consumption')
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    newInboundQuantity($event, totalQuantityRemaining) {
      const quantity = $event
      const remainingQuantity = parseInt(totalQuantityRemaining)
      const newInboundedQuantity = parseInt(quantity)
      if (newInboundedQuantity > remainingQuantity) {
        this.$notification.error({
          message: 'Total inbounded quantity is crossing remaining quantity',
        })
        this.hub_stock.consumption_amount = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
